<template>
  <Pcenheader></Pcenheader>
  <Pcenhome :objdata="data"></Pcenhome>

  <!-- 重要通知弹框 draggable-->
  <el-dialog  v-model="data2.dialogShow" :width="1000/128+'rem'" top="12%" v-if="data2.dialogif">
      <div class="zytz_dialog">
        <h1>{{'Important Notice'}}</h1>
        <nav>
          <p v-if="data2.content.description_en">{{data2.content.description_en}}</p>
          <p v-html="data2.content.content_en"></p>
        </nav>
      </div>
  </el-dialog>
</template>

<script setup>
import Pcenheader from '@/components/englishCom/endheader.vue'
import Pcenhome from '@/views/english/englishHome/pcenhome.vue'
import * as http from '@/api/API.js'
import { reactive,ref } from 'vue'
const data = reactive({
  bannerList:[],
  classData:{
    news:{item:[],title_en:''},
    seminars:{item:[],title_en:''},
    conferences:{item:[],title_en:''},
    announcements:{item:[],title_en:''},
  },
  rightBut:[{title_en:"加载中...",id:100}],
  video:[{url:''}]
})
const data2 = reactive({
  dialogShow:false,
  dialogif:false,
  content:'',
})

// 获取轮播图
const bannerList = async () => {
  const {data:res} = await http.getHttp("/api/banner",{is_en:1})
  if(res.code == 200){
    getHome()
    data.bannerList = res.data
  }
}
bannerList()
const getHome = () => {
  http.get_home_list({is_en:1}).then(res => {
    if(res.data.code == 200){
      getvideo()
      // 处理各个模块的数据。
      data.classData = {
        news:res.data.data.find(item => item.id == 318),
        seminars:res.data.data.find(item => item.id == 322),
        conferences:res.data.data.find(item => item.id == 323),
        announcements:res.data.data.find(item => item.id == 321),
      }
      // 获取并处理首页右侧的按钮，以便循环
      data.rightBut = res.data.data.find(item => item.id == 305)
    }
  })
}
const getvideo = () => {
  http.getHttp('/api/video',{is_en:1}).then(res => {
    data.video = res.data.data
    getSetup()
  })
}
// 获取弹框接口数据
let getSetup = async () => {
  const {data:res} = await http.getSetup({is_en:1})
  data2.content = res.data
  res.data.value == '0' ? data2.dialogShow = false : data2.dialogShow = true
}

</script>

<style lang="less" scoped>
@maxw:128rem;
.zytz_dialog{
  width: 100%;
  // height: 700/@maxw;
  overflow: auto;
  margin-top: -30/@maxw;
  h1{
    box-sizing: border-box;
    font-size: 32/@maxw;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0 10/@maxw 0 0;
    padding: 0 0 20/@maxw 0;
  }
  nav{
    font-size: 22/@maxw;
  }
}
.zytz_dialog::-webkit-scrollbar{
  width: 5px;
  background-color: #355f9f30;
  border-radius: 20px;
  padding-top:20px !important;
}
.zytz_dialog::-webkit-scrollbar-thumb {
  width: 5px;
  // height: 10px;
  background-color: #1b5bbc97;
  border-radius: 20px;
  padding-top:20px !important;
}
/deep/.el-dialog__headerbtn{
  right: 10/@maxw;
}

</style>
