<template>
  <!-- <component :is='data.navmenu' :navlist='[]'></component> -->
  <div class="pchome">
    <div class="swiperBox">
      <swiper 
        class="swiper" 
        :modules="modules" 
        :slides-per-view="1" 
        :speed="1500" :loop="true"
        :autoplay="{delay: 4000}" 
        navigation
        @slideChange="onSlideChange" 
        @swiper="onSwiper">
        <swiper-slide v-for="(item,i) in data.banners" :key="i" class="swiper_slide_img">
          <div class="button" v-if="item.is_more == 1" @click="swiperButton(item)"><img src="@/assets/button.png"></div>
          <img :src="item.url" alt="" style="width:100%;height:100%;object-fit: cover;" v-if="i !== 0">
          <video muted preload="none" autoplay :onended="onended" style="width:100%;height:100%;object-fit: cover;" :src="item.url" v-else></video>
        </swiper-slide>
      </swiper>
        <!-- 自定义swiper分页 -->
      <ul class="swiperPag" ref="swiperPag"> 
        <li v-for="(item,i) in data.banners" :key="item.id" @click="onliimg(i)"></li>
      </ul>
    </div>
    <!-- 英文页面主体部分 -->
    <div class="pcenNav">
      <section class="section">
        <div class="left">
          <div class="sec1 styles">
            <div style="display:flex;justify-content: space-between;">
              <div class="topTitle"><img src="@/assets/enbacktitle.png"><span>{{data.classData.news.title_en}}</span></div>
            <div class="more" @click="rightbut(data.classData.news)"><img src="@/assets/articleicon.png"><span>MORE</span></div>
            </div>
            <nav class="navs"> 
              <div class="leftbox">
                <component :is="cumCompents(item)" v-for="(item, i) in newsleftdata(data.classData.news.item)" 
                :key="i"
                :data="item"
                :id="item.id"
                :Parent="data.classData.news"
                >
              </component>
              </div>
              <!-- v-for="(item, i) in data.classData.news.item.slice(leftBoxitem(data.classData.news.item))" -->
              <div class="rightbox">
                <component 
                :is="cumCompents(item)"
                v-for="(item, i) in newsleftdata(data.classData.news.item,'right')" 
                :key="i"
                :data="item"
                :id="item.id"
                :Parent="data.classData.news"
                >
              </component>
              </div>
            </nav>
          </div>  
          <div class="sec2">
            <div class="leftnav styles">
              <div style="display:flex;justify-content: space-between;">
                <div class="topTitle"><img src="@/assets/enbacktitle.png"><span>CONFERENCES</span></div>
                <div class="more" @click="rightbut(data.classData.conferences)"><img src="@/assets/articleicon.png"><span>MORE</span></div>
              </div>
             <nav class="navs">
              <component 
                :is="cumCompents(item) "
                v-for="(item, i) in data.classData.conferences.item" 
                :key="i"
                :data="item"
                :Parent="data.classData.conferences"
                :id="item.id"
                >
              </component>
             </nav>
            </div>
            <div class="rightnav styles">
              <div style="display:flex;justify-content: space-between;">
                <div class="topTitle"><img src="@/assets/enbacktitle.png"><span>ANNOUNCEMENTS</span></div>
                <!-- <div class="more moreedit" @click="rightbut(data.classData.announcements)"><img src="@/assets/articleicon.png"><span>MORE</span></div> -->
                <div class="more" @click="rightbut(data.classData.announcements)"><img src="@/assets/articleicon.png"><span>MORE</span></div>
              </div>

            <nav class="navs">
              <component 
                :is="cumCompents(item)"
                v-for="(item, i) in data.classData.announcements.item" 
                :key="i"
                :data="item"
                :Parent="data.classData.announcements"
                :id="item.id"
                >
              </component>
            </nav>
          </div>
          </div>
        </div>
        <div class="right">
          <div class="nav1 styles">
            <div class="topTitle"><img src="@/assets/enbacktitle.png"><span>PROGRAMS</span></div>
            <nav class="navs">
              <div class="rightbut" v-for="(item,i) in props.objdata.rightBut.item" :key="item.id" @click="rightbut(item)">
                <span>{{item.title_en}}</span>
                <img src="@/assets/enhomeicon.png">
              </div>
            </nav>
          </div>
          <div class="nav2 styles">
              <div style="display:flex;justify-content: space-between;">
                <div class="topTitle"><img src="@/assets/enbacktitle.png"><span>SEMINARS</span></div>
                <div class="more" @click="rightbut(data.classData.seminars)"><img src="@/assets/articleicon.png"><span>MORE</span></div>
              </div>
              <nav class="navs">
                <component 
                :is="cumCompents(item)"
                v-for="(item, i) in data.classData.seminars.item" 
                :key="i"
                :data="item"
                :Parent="data.classData.seminars"
                :id="item.id"
                >
              </component>
              </nav>
          </div>
        </div>
      </section>
      <div class="videos styles">
          <div style="display:flex;justify-content: space-between;">
              <div class="topTitle"><img src="@/assets/enbacktitle.png"><span>Video</span></div>
              <div class="more" @click="rightbut({vue_url:'Envideo',id:359,tid:359})"><img src="@/assets/articleicon.png"><span>MORE</span></div>
          </div>
          <nav class="navs" @click="videoControls" ref="videoBox">
            <img :src="data.baseUrl+'uploads/images/4e5cd0ea34f35ec8e905af8ac93fe64f.jpg'" alt="">
            <video :src="data.baseUrl+props.objdata.video[0].url" controls ref="video"></video>
          </nav> 
      </div>
    </div>
    <!-- 公共底部 -->
    <component :is='data.footers' :en="true"></component>
  </div>
</template>

<script setup>
// import navm from '@/components/phonenavmenu.vue'
import foot from '@/components/footerPhone.vue'
import Pcenfooter from '@/components/englishCom/enfooter.vue'

import titleText from '@/components/englishCom/enHometemplate/title_text.vue'
import minTitletext from '@/components/englishCom/enHometemplate/min_title_text.vue'
import maxTitletext from '@/components/englishCom/enHometemplate/max_title_text.vue'
import leftimgTitle from '@/components/englishCom/enHometemplate/left_img_title.vue'

import { ref,onMounted,watch, nextTick, reactive,defineProps,computed } from 'vue'
import { useRouter, } from 'vue-router'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import { Navigation, Pagination, Scrollbar, A11y,Autoplay, } from "swiper";
const modules = reactive([Navigation, Pagination, Scrollbar, A11y,Autoplay,])
const router = useRouter()
const props = defineProps({
  objdata:{}
})
const data = reactive({
  baseUrl:process.env.VUE_APP_BASE_URL+'/',
  swiper: {},
  comArr: [titleText, maxTitletext, minTitletext, leftimgTitle],
  components: {
    titleText,
    minTitletext,
    maxTitletext,
    leftimgTitle,
  },
  banners:[],
  classData:{
    news:{item:[],title_en:''},
    seminars:{item:[],title_en:''},
    conferences:{item:[],title_en:''},
    announcements:{item:[],title_en:''},
  },
  footers:Pcenfooter,
  topheader:false,
  navmenu:''
})
// 获取自定义swiper分页dom元素
const swiperPag = ref(null)
// 获取swiper实例
const onSwiper = (e) => {  data.swiper = e }

onMounted(() => {
  data.swiper.autoplay.stop()
  data.swiper.slideTo(1)
  nextTick(()=>setTimeout(()=>setlibackground(0),1000))
  // 判断窗口大于750
const getphoneWidht = () => {
  let widthWind = window.innerWidth
  sizeWidth()
  window.addEventListener("resize",() => {
    widthWind = window.innerWidth
    sizeWidth()
  })
    function sizeWidth(){
      if(widthWind < 750){
      data.footers = foot
      // data.navmenu = navm
      data.topheader = true
    }else{
      data.footers = Pcenfooter
      data.navmenu = ''
      data.topheader = false
    }
    }
}
getphoneWidht()
})
// swiper轮播事件
const  onSlideChange = (e) => {
  const { realIndex } = e
  if(swiperPag.value.children.length==0)return
  setlibackground(realIndex)
}
// 动态给自定义swiper分页设置背景色
const setlibackground = (e) => {
  const li = swiperPag.value&&swiperPag.value.children
  for(let i = 0; i < li.length; i ++){
    li[i].style = { backgroundColor: '#707070', opacity: 0.6 }
  }
  const lis = li[e].style
  lis.backgroundColor = '#FFFFFF'
  lis.opacity = 1
}
// 当首页banner区域视频播放完毕执行事件
const onended = () => {  data.swiper.autoplay.start() }
// 点击分页器切换banner
const onliimg = (e) => {
  data.swiper.slideToLoop(e)
  setlibackground(e)
}
// 处理父级传过来的数据
const propsSet = () => {
  data.banners = props.objdata.bannerList
  data.classData = props.objdata.classData
}
// 点击轮播按钮
const swiperButton = (e) => {
  router.push({
    name: 'Enyzjy',
    params:{
      id:398,
      tid:303,
      level:1
    }
  })
  // if(e.link_type == 1){
  //     router.push({
  //     name: 'Enarticlexq',
  //     params:{
  //       articleid:e.link,
  //       id:e.id,
  //       tid:303,
  //       level:1
  //     }
  //   })
  //   return
  // }
  // window.open(e.link)
}
// 监听父组件传过来的值变化
watch(() => props.objdata,() => {
  propsSet()
},{deep:true})

// 计算属性动态计算要用到的组件
const cumCompents = computed(() => (item) => {
  let comp
  switch(item.article_template){
    case '1': comp =  titleText ;break
    case '2': comp =  minTitletext ;break
    case '3': comp =  maxTitletext ;break
    case '4': comp =  leftimgTitle ;break
    case '5': comp =  titleText ;break
    case '6': comp =  minTitletext ;break
    case '7': comp =  maxTitletext ;break
  }
  return comp
})
const videoBox = ref(null)
const video = ref(null)
// 点击右边入口按钮
const rightbut = (e) => {
  if(e.external_url){
    window.open(e.external_url)
    return
  }
  router.push({
    name: e.vue_url,
    params:{
      id:e.id,
      tid:e.tid,
    }
  })
}
// 点击播放视频
const videoControls = () => {
  const videoBoxs = videoBox.value.children
    videoBoxs[0].style.transform = 'scale(0)';
    videoBoxs[0].style.opacity = '0'
    videoBoxs[1].play()
}
// 处理news新闻数据，让左右文章按顺序排列
const newsleftdata = (eee,str) => {
  let arrdatas1 = []
  let arrdatas2 = []
  eee.forEach((item, i) => {
    if (i % 2 == 0) {
      arrdatas1.push(item)
    } else {
      arrdatas2.push(item)
    }
  })
  return str == 'right' ? arrdatas2 : arrdatas1
}
</script>

<style lang="less" scoped>
@maxw:128rem;
.pchome{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -999999999999;
  margin-top: 215/@maxw;
  width: 1920/@maxw;
  height: 865/@maxw;
  .swiperBox{
    position: relative;
    width: 100%;
    height: 100%;
    .swiper{
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .swiperPag{
      position: absolute;
      z-index: 9999;
      bottom: 62/@maxw;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      li{
        cursor: pointer;
        width: 65/@maxw;
        height: 5/@maxw;
        border-radius: 50px;
        background-color: #707070;
        margin-right: 15/@maxw;
        opacity: 0.6;
      }
    }
    .button{
      cursor: pointer;
      position: absolute;
      z-index: 9999;
      bottom:339/@maxw;
      left: 297/@maxw;
      width: 204/@maxw;
      height: 65/@maxw;
      transition: ease .3s;
      img{width: 100%;height: 100%;vertical-align: middle;}
    }
    .button:hover{
      transform: scale(1.07);
    }
  }
.pcenNav{
  margin: 0;
  box-sizing: border-box;
  padding: 42/@maxw 70/@maxw 68/@maxw 70/@maxw;
  width: 1920/@maxw;
  background-color: #fff;

  .section{
    display: flex;
    justify-content: space-between;
    .left{
      width: 1180/@maxw;
      .sec1{
        width: 100%;
        min-height: 1304/@maxw;
        background-color: #F2F2F2;
        margin-bottom: 16/@maxw;
        >nav{
          position: relative;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          min-height: 1152/@maxw;
          .leftbox,.rightbox{
            width: 534/@maxw;
            background-color: #F2F2F2;
            display: flex;
            flex-direction: column;
          }
          // .rightbox{
          // }
        }
        >nav::after{
           position: absolute;
           top: 0;
           left: 50%;
           transform: translateX(-50%);
           z-index: 99999;
           content: "";
           width: 1px;
           height: 100%;
           background-color: #CBCBCB;
      }
      }
      .sec2{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .leftnav{
          width: 582/@maxw;
          // min-height: 2180/@maxw;
          min-height: 60/@maxw;
          background-color: #F2F2F2;
        }
        .rightnav{
          width: 582/@maxw;
          // min-height: 2180/@maxw;
          min-height: 60/@maxw;
          background-color: #F2F2F2;
        }
      }
    }
    .right{
      width: 582/@maxw;
      min-height: 1000px;
      .nav1{
        width: 582/@maxw;
        height: 792/@maxw;
        background-color: #F2F2F2;
        >nav{
          height: 640/@maxw;
          .rightbut{
            transition: ease .2s;
            box-sizing: border-box;
            cursor: pointer;
            color: #0D2F62;
            font-size: 25/@maxw;
            font-weight: bold;
            width: 100%;
            height: 90/@maxw;
            text-align: center;
            line-height: 90/@maxw;
            margin-bottom: 20/@maxw;
            background:linear-gradient(150deg, #EFE2D8 20%, #C7AF98 100%);
            img{
              width: 7/@maxw;
              height: 11/@maxw;
              margin: 0 0 3/@maxw 16/@maxw;
            }
          }
          .rightbut:hover{
            background: linear-gradient(to right,#387DE2,#174994);
            color: #EFE2D8;
            >img{visibility: hidden;}
          }
          >.rightbut:nth-child(3){
            display: inline-block;
            width: 343/@maxw;
          }
          >.rightbut:nth-child(4){
            // display: inline-block;
            width: 171/@maxw;
            float: right;
          }
          >.rightbut:last-child{
            margin-bottom: 0 !important;
          }
        }
      }
      .nav2{
        margin-top: 16/@maxw;
        width: 582/@maxw;
        // min-height: 2692/@maxw;
        min-height: 60/@maxw;
        background-color: #F2F2F2;
        .moreedit{
          margin: 4/@maxw 0 0 12/@maxw;
        }
      }
    }
  }
  .videos{
      margin-top: 16/@maxw;
      width: 1778/@maxw;
      height: 930/@maxw;
      background-color: #F2F2F2;
      >nav{
        cursor: pointer;
        position: relative;
        width: 100%;
        height: 778/@maxw;
        >img{
          transform: scale(1);
          transition: ease .5s;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        video{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
}
}
// 公用样式
.styles{
  box-sizing: border-box;
  padding: 26/@maxw 24/@maxw 24/@maxw 24/@maxw;
}
// 公用模块头部
.topTitle{
  position: relative;
  display: flex;
  align-items: center;
  width: 38/@maxw;
  height: 76/@maxw;
  img{
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
  span{
    position: absolute;
    white-space: nowrap;
    left: 13/@maxw;
    font-size: 40/@maxw;
    font-weight: bold;
    color: #174994;
    font-family: Georgia;
  }
}
// 公用默认
.more{
  cursor: pointer;
  font-family: Georgia;
  display: flex;
  align-items: center;
  img{
    width: 25/@maxw;
    height: 25/@maxw;
  }
  span{
    font-size: 22/@maxw;
    color: #174994;
    margin-left: 15/@maxw;
  }
  >span:hover{
    color: #174994;
    font-weight: bold;
  }
}
// 公用主体nav
.navs{
  width: 100%;
  margin-top: 26/@maxw;
}
// 修改swiper左右按钮样式
/deep/.swiper-button-next:after,/deep/.swiper-button-prev:after{
  font-size: 30/@maxw !important;
  color: #fff;
  font-weight: bold !important;
}
/deep/.swiper-button-prev{
left: 67/@maxw;
}
/deep/.swiper-button-next{
  right: 67/@maxw;
}

@media screen and (max-width:750px) {
  @maxw:50rem;
  .pchome{
    width: 750/@maxw;
    height: 440/@maxw;
    margin: 0 auto;
    .pcenNav{
      width: 100%;
      .section{width: 100%;display: flex;flex-wrap: wrap;
        .left{ 
          width: 100%;
          .sec1{
            width: 100%;
            >nav{width: 100%;display: flex;flex-direction: column;
              .leftbox,.rightbox{width: 100%;background-color: #F2F2F2;display: flex;flex-direction: column;align-items: center;
            }
            .rightbox{ margin-top: 23/@maxw; }
            }
            >nav::after{ display: none; }
          }
          .sec2{width: 100%;display: flex;flex-direction: column;
            .leftnav,.rightnav{width: 100%;height: auto;
              .navs{ display: flex;justify-content: center;flex-wrap: wrap;}
            }
            .rightnav{margin-top: 7/@maxw;}
          }
        }
        .right{margin: 6/@maxw 0 0 0;width: 100%;
          .nav1{width: 582/@maxw;height: 792/@maxw;background-color: #F2F2F2;
            >nav{
              height: 640/@maxw;
              .rightbut{
            font-size: 25/@maxw;
            height: 90/@maxw;
            line-height: 90/@maxw;
            margin-bottom: 20/@maxw;
            img{
              width: 7/@maxw;
              height: 11/@maxw;
              margin: 0 0 3/@maxw 16/@maxw;
            }
              }
              >.rightbut:nth-child(3){
                width: 450/@maxw !important;
              }
              >.rightbut:nth-child(4){
                width: 171/@maxw;
              }
            }
      }
          .nav1,.nav2{ width: 100%; }
          .nav2{
            >.navs{ display: flex;justify-content: center;flex-wrap: wrap;}
          }
        }
      }
    }
    .videos{
      overflow: hidden;
      margin-top: 16/@maxw;
      width: 1778/@maxw;
      height: auto !important;
      >nav{height: 778/@maxw;}
    }
  }
  
.styles{
  padding: 26/@maxw 24/@maxw 24/@maxw 24/@maxw;
}
// 公用模块头部
.topTitle{ width: 38/@maxw;height: 76/@maxw;
  span{left: 13/@maxw;font-size: 40/@maxw;}}
// 公用默认
.more{
  img{width: 25/@maxw;height: 25/@maxw;}
  span{font-size: 22/@maxw; margin-left: 15/@maxw;}
}
// 公用主体nav
.navs{ margin-top: 26/@maxw;}
}
</style>