<template>
  <div class="box" @click="pushArticle">
    <div class="imgbox"><img :src="props.data&&baseUrl+props.data.thumb"></div>
    <header>
      <nav>{{props.data&&props.data.title_en}}</nav>
    </header>
    <div class="time" v-if="props.data&&props.data.article_template=='6'">
      <img src="@/assets/Timeicon.png">
      <!-- <span>10:00-10:00 on Wednesday, October 26, 2022</span> -->
      <span>{{props.data&&props.data.lecture_time}}</span>
    </div>
    <section>
      <nav>{{props.data&&props.data.description_en}}</nav>
      <span>{{props.data&&props.data.created_at.slice(0,10)}}</span>
    </section>
  </div>
</template>

<script setup>
import { defineProps ,ref,reactive} from 'vue';
import { useRouter, } from 'vue-router'
const router = useRouter()
const props = defineProps({
  data:{},
  Parent:{},
  id:''
})
const pushArticle = () => {
  const e = props.Parent
  router.push({
    name:'Enarticlexq',
    params:{
      articleid:props.id,
      id:e.id,
      tid:e.tid,
      // tid:303,
      // level:1
    }
  })
}
const baseUrl = ref(process.env.VUE_APP_BASE_URL);
</script>

<style lang="less" scoped>
@maxw: 128rem;
.box{
  cursor: pointer;
  width: 534/@maxw;
  margin-bottom: 24/@maxw;
  overflow: hidden;
  .imgbox {
      width: 100%;
      height: 312/@maxw;
      overflow: hidden;
      >img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
        transition: ease .3s;
        // object-fit: cover;
      }
    }
  >header{
    width: 100%;
    max-height: 161/@maxw;
    background:linear-gradient(156deg, #F7F1EC 0%, #CCB8A6 100%);
    box-sizing: border-box;
    padding: 30/@maxw 40/@maxw 30/@maxw 24/@maxw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 29/@maxw;
    font-family: Georgia;
    font-weight: bold;
    color: #174994;
    nav{
          word-break: break-word;//英文换行不断词
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          line-height: 40/@maxw;
    }
  }
  >section{
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 24/@maxw; 
    font-size: 20/@maxw;
    color: #393939;
    >nav{
          width: 486/@maxw;
          padding: 0;
          margin: 0;
          word-break: break-word;//英文换行不断词
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          line-height: 30/@maxw;
          overflow: hidden;
    }
    >span{
      display: inline-block;
      margin-top: 12/@maxw;
      font-size: 18/@maxw;
      color: #174994;
    }
  }
  .time{
    display: flex;
    align-items: center;
    width: 100%;
    height: 40/@maxw;
    background: linear-gradient(to right,#174994,#387DE2);
    img{
      width: 22/@maxw;
      height: 22/@maxw;
      vertical-align: middle;
      margin:0 10/@maxw 0 25/@maxw;
    }
    span{
      font-size: 16/@maxw;
      color: #fff;
    }
  }
}
.box:hover {
  >header {
    text-decoration: underline;
  }
  img{
    transform: scale(1.05);
  }
}
.box:last-child{
  margin-bottom: 0 !important;
}
@media screen and (max-width:750px) {
  @maxw:50rem;
  .box{
  width: 534/@maxw;
  margin-bottom: 24/@maxw;
  .imgbox {
      width: 100%;
      height: 312/@maxw;
    }
  >header{
    max-height: 161/@maxw;
    padding: 30/@maxw 40/@maxw 30/@maxw 24/@maxw;
    font-size: 29/@maxw;
    nav{
        line-height: 40/@maxw;
    }
  }
  >section{
    padding: 24/@maxw; 
    font-size: 20/@maxw;
    color: #393939;
    >nav{
          width: 486/@maxw;
          line-height: 30/@maxw;
    }
    >span{
      margin-top: 12/@maxw;
      font-size: 18/@maxw;
    }
  }
  .time{
    height: 40/@maxw;
    img{
      width: 22/@maxw;
      height: 22/@maxw;
      margin:0 10/@maxw 0 25/@maxw;
    }
    span{
      font-size: 16/@maxw;
    }
  }
}
}
</style>