<template>
  <div class="box" @click="pushArticle">
    <div class="imgbox"><img :src="props.data&&baseUrl+props.data.thumb"></div>
    <nav>
      <div class="top"><section>{{props.data&&props.data.title_en}}</section></div>
      <div class="bottom">
        <section>{{props.data&&props.data.description_en}}</section>
        <span>{{props.data&&props.data.created_at.slice(0,10)}}</span>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { defineProps ,ref,reactive} from 'vue';
import { useRouter, } from 'vue-router'
const router = useRouter()
const props = defineProps({
  data:{},
  Parent:{},
  id:""
})
const pushArticle = () => {
  const e = props.Parent
  router.push({
    name:'Enarticlexq',
    params:{
      articleid:props.id,
      id:315,
      tid:e.tid,
      // tid:303,
      // level:2
    }
  })
}
const baseUrl = ref(process.env.VUE_APP_BASE_URL);
</script>

<style lang="less" scoped>
@maxw: 128rem;
.box{
  cursor: pointer;
  width: 534/@maxw;
  height: 424/@maxw;
  margin-bottom: 24/@maxw;
  display: flex;
  justify-content: center;
  background-color: #fff;
  .imgbox {
      width: 227/@maxw;
      height: 404/@maxw;
      overflow: hidden;
      >img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
        transition: ease .3s;
        object-fit: cover;
      }
    }
  nav{
    width: 307/@maxw;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top{
      background:linear-gradient(156deg, #F7F1EC 0%, #CCB8A6 100%);
      width: 307/@maxw;
      max-height: 200/@maxw;
      box-sizing: border-box;
      padding: 24/@maxw;
      font-weight: bold;
      color: #174994;
      font-size: 29/@maxw;
      font-family: Georgia;
      >section{
        word-break: break-word;//英文换行不断词
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          line-height: 40/@maxw;
      }
    }
    .bottom{
      box-sizing: border-box;
      padding: 22/@maxw  40/@maxw  33/@maxw;
      font-size: 20/@maxw;
      color: #393939;
      >section{
        word-break: break-word;//英文换行不断词
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
      }
      >span{
      // padding: 0;
      display: inline-block;
      margin-top: 25/@maxw;
      font-size: 18/@maxw;
      color: #174994;
    }
    }
  }

}
.box:hover {
  img {
    transform: scale(1.05);
  }
     
}
.box:last-child{
  margin-bottom: 0 !important;
}
</style>